<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm5>
                <v-card class="elevation-12">
                    <v-toolbar flat dense color="yellow">
                        <v-toolbar-title>Formulario de cambio de contraseña</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container fill-height>
                                <v-flex xs12 pa-1>
                                    <v-text-field prepend-inner-icon="mdi-lock"
                                                  name="password"
                                                  label="Password"
                                                  :append-icon="
                                            show1 ? 'mdi-eye' : 'mdi-eye-off'
                                        "
                                                  :type="show1 ? 'text' : 'password'"
                                                  @click:append="show1 = !show1"
                                                  required
                                                  v-model="password"
                                                  :rules="passwordRules"
                                                  data-cy="joinPasswordField">
                                    </v-text-field>
                                </v-flex>
                                <v-flex xs12 pa-1>
                                    <v-text-field prepend-inner-icon="mdi-lock"
                                                  label="Confirma Password"
                                                  :append-icon="
                                            show2 ? 'mdi-eye' : 'mdi-eye-off'
                                        "
                                                  :type="show2 ? 'text' : 'password'"
                                                  @click:append="show2 = !show2"
                                                  required
                                                  v-model="confirmpassword"
                                                  :rules="passwordRules"
                                                  data-cy="joinPasswordField"></v-text-field>
                                </v-flex>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="yellow"
                               :disabled="!valid"
                               @click="submit"
                               data-cy="joinSubmitBtn">
                            Confirma
                            <span>
                                <v-progress-circular indeterminate
                                                     v-if="progress">
                                </v-progress-circular>
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <template>
                    <div class="text-center">
                        <v-snackbar v-model="message" top light color="error">
                            {{ message }}
                            <v-btn text @click="clear">
                                Ok
                            </v-btn>
                        </v-snackbar>
                    </div>
                </template>
                <template>
                    <v-row justify="center">
                        <v-dialog v-model="dialog" persistent max-width="290">
                            <v-card>
                                <v-card-title class="headline">Verificación de email</v-card-title>
                                <v-card-text>
                                    Se ha registrado correctamente el nuevo
                                    usuario, En breve recibirá un email con un
                                    link para validar la direccion de correo
                                    electrónico.
                                </v-card-text>
                                <v-card-actions>
                                    <div class="flex-grow-1"></div>
                                    <v-btn color="green darken-1"
                                           text
                                           @click="fin">Ok</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </template>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'Join',
        data() {
            return {
                dialog: false,
                valid: false,
                loading: false,
                show1: false,
                show2: false,
                password: '',
                confirmpassword: '',
                passwordRules: [
                    v => !!v || 'Password is required',
                    v =>
                        v.length >= 6 || 'Password debe tener mï¿½s de 6 caracteres'
                ]
            };
        },
        computed: {
            ...mapState('account', [
                'user',
                'loggedIn',
                'progress',
                'error',
                'processed'
            ]),
            ...mapState('alert', ['type', 'message'])
        },
        methods: {
            ...mapActions('account', ['reset', 'logout']),
            ...mapActions('alert', ['clear']),
            submit() {
                this.loading = true;
                if (this.$refs.form.validate()) {
                    this.reset({
                        userId: this.userId,
                        code: this.code,
                        password: this.password
                    });
                }
                this.loading = false;
            },
            fin() {
                this.$router.push('/');
            }
        },
        mounted() {
            this.userId = this.$router.history.current.query.userId;
            this.code = this.$router.history.current.query.code;
            //this.logout();
            const { userId, code } = this;
        }
    };
</script>

<style scoped></style>
